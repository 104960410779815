/** @jsxImportSource @emotion/react */
import { css, keyframes } from "@emotion/react";

const loaderContainerStyle = css`
  text-align: center;
  flex-grow: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3em;
  background-color: #333;
  color: #fff;
`;

const blinkAnimation = keyframes`
  from {
    opacity: 1
  }
  50% {
    opacity: .3
  }

  to {
    opacity: 1
  }
`;

const loaderTextStyle = css`
  animation: ${blinkAnimation} 1s ease-in-out infinite;
`;

const Loader = () => {
  return (
    <div css={loaderContainerStyle}>
      <div css={loaderTextStyle}>Loading...</div>
    </div>
  );
};

export default Loader;
