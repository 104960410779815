/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from "react";
import Canvas from "./components/Canvas";
import Visualization from "./Visualization";
import { WebGLRenderer, Vector3 } from "three";
import { css } from "@emotion/react";
import { FinalData, parsePolyline } from "./App";
import { Text, Hud, PerspectiveCamera } from "@react-three/drei";

type Props = {
  polyline: string;
};

const canvasContainerStyle = css`
  flex-grow: 1;
  background-color: #333;
  flex-shrink: 1;
  min-width: 0px;
`;

const containerStyle = css`
  display: flex;
  align-items: stretch;
  height: 100%;
`;

const titlePosition = new Vector3(-12.5, 11, 0);
const placePosition = new Vector3(-12.5, 9.5, 0);
const metricsPosition = new Vector3(-12.5, 8.5, 0);
const trademarkPosition = new Vector3(13, -13, 0);

const VideoRender = ({ polyline }: Props) => {
  const rendererRef = useRef<WebGLRenderer | null>(null);
  const [data, setData] = useState<FinalData[] | null>(null);
  const [tiles, setTiles] = useState<FinalData[][] | null>(null);
  const [generatingVideo, setGeneratingVideo] = useState(true);
  useEffect(() => {
    parsePolyline(polyline).then((data) => {
      setData(data.data);
      setTiles(data.tiles);
    });
  }, []);
  return (
    <div css={containerStyle}>
      <div css={canvasContainerStyle}>
        <Canvas>
          {data && tiles && (
            <>
              <Hud>
                <Visualization
                  tiles={tiles}
                  data={data}
                  rendererRef={rendererRef}
                  locInteractions={false}
                  autoDownload={false}
                  generatingVideo={generatingVideo}
                  onVideoFinish={() => setGeneratingVideo(false)}
                />
              </Hud>
              <Hud renderPriority={2}>
                <group>
                  <PerspectiveCamera makeDefault position={[0, 0, 30]} />
                  <Text fontSize={1.3} color="#9e9e9e" position={titlePosition} anchorX="left" anchorY="top-baseline">
                    Afternoon Ride
                  </Text>
                  <Text fontSize={.9} color="#757575" position={placePosition} anchorX="left" anchorY="top-baseline">Mieroszów</Text>
                  <Text fontSize={.9} color="#757575" position={metricsPosition} anchorX="left" anchorY="top-baseline">44.56 km  •  123m 36s</Text>
                  <Text
                    fontSize={0.8}
                    color="#9e9e9e"
                    position={trademarkPosition}
                    anchorX="right" anchorY="bottom-baseline"
                  >
                    Powered by PaintMove.com
                  </Text>
                </group>
              </Hud>
            </>
          )}
        </Canvas>
      </div>
    </div>
  );
};

export default VideoRender;
