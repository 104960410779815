import App from "./App";
import VideoRender from "./VideoRender";

const urlParams = new URLSearchParams(window.location.search);
const polyline = urlParams.get("polyline");

const Router = () => {
  return (
    <>
      {polyline && <VideoRender polyline={polyline} />}
      {!polyline && <App />}
    </>
  );
};

export default Router;
