/** @jsxImportSource @emotion/react */
import * as React from "react";
import { css } from "@emotion/react";
import Menu from "./Menu";
import paintMoveLogo from "../paintmove.svg";

const sidebarStyle = (showMenu: boolean) => css`
  max-height: 100%;
  display: flex;
  flex-direction: column;
  width: 240px;
  background-color: #fff;
  transition: transform 200ms;

  @media (max-width: 550px) {
    position: fixed;
    z-index: 10;
    transform: translateX(${showMenu ? "0" : "-100%"});
  }
`;

const headerContainerStyle = css`
  padding: 10px;

  h2 {
    margin: 5px 10px;
    font-size: 1em;
    color: #000;
  }
`;

const activitiesContainerStyle = css`
  max-height: 100%;
  min-height: 0;
  overflow-y: auto;
  flex-shrink: 1;
  padding: 10px;
`;

const footerStyle = css`
  text-align: center;
  padding: 10px;
  font-size: 0.9em;
  border-top: 1px solid #eee;
`;

const logoStyle = css`
  max-width: 100px;
`;

const mobileMenuButton = css`
  display: none;
  -webkit-apperance: none;
  border: 0;
  font-size: inherit;
  color: inherit;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  width: 50px;
  height: 50px;

  @media (max-width: 550px) {
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    z-index: 8;
    top: 10px;
    left: 10px;
  }
`;

type Props = {
  children: React.ReactNode;
  showMenu: boolean;
  onMenuButtonClick: () => void;
};

const Sidebar = ({ children, onMenuButtonClick, showMenu }: Props) => {
  return (
    <>
      <button css={mobileMenuButton} onClick={onMenuButtonClick}>
        <Menu />
      </button>
      <div css={sidebarStyle(showMenu)}>
        <div css={headerContainerStyle}>
          <h2>Activities</h2>
        </div>
        <div css={activitiesContainerStyle}>{children}</div>
        <div css={footerStyle}>
          <a href="https://paintmove.com">
            <img src={paintMoveLogo} css={logoStyle} alt="PainMove" />
          </a>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
