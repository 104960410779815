const Menu = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="35"
      width="35"
      viewBox="0 0 48 48"
    >
      <path d="M6 36v-3h36v3Zm0-10.5v-3h36v3ZM6 15v-3h36v3Z" />
    </svg>
  );
};

export default Menu;
