/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import paintMoveLogo from "./paintmove.svg";

const containerStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
`;

const loginButtonStyle = css`
  color: #fff;
  background-color: #7158d3;
  border-radius: 12px;
  text-decoration: none;
  padding: 10px 15px;
`;

const StartScreen = () => {
  return (
    <div css={containerStyle}>
      <h1>
        <img src={paintMoveLogo} alt="PainMove" />
      </h1>
      <p>Login to your Strava account, to fetch your activities</p>
      <a
        css={loginButtonStyle}
        href={`https://www.strava.com/oauth/authorize?client_id=${process.env.REACT_APP_STRAVA_CLIEND_ID}&redirect_uri=${process.env.REACT_APP_SERVER_ADDRESS}/auth&response_type=code&approval_prompt=auto&scope=activity:read`}
      >
        Login to Strava
      </a>
    </div>
  );
};

export default StartScreen;
