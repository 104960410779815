import React, { useEffect, useMemo } from "react";
import {
  Vector3,
  Mesh,
  CatmullRomCurve3,
  PerspectiveCamera as RawPerspectiveCamera,
} from "three";
import { Encoder } from "./encoder";
import { useFrame, useThree } from "@react-three/fiber";
import {
  OrbitControls,
  Tube,
  PerspectiveCamera,
  Line,
} from "@react-three/drei";
import SphericalMercator from "@mapbox/sphericalmercator";
import { FinalData } from "./App";

// const samplePolyline =
// ("apsvHw|yfBTXb@EHBJNMv@A@Rt@g@dFFJb@H@JsAlO]jFEP]`@GJD`@L`@?`@Il@WrCa@nHC~@UjDYbDMzBOtAgAvGm@dFOx@Ut@OdBGVMF@b@k@\\YZCJj@bBNl@JRHp@L^n@dDPf@l@`Ef@`Bh@nDCtA\\tCATDn@h@|Bf@vCXjADP?TDTbBzFPfAFH^LRVXf@jArC\\h@xAzCf@lAZbAFNb@PTZbCrFbA`D`@n@rA`DBPARSzAg@zAa@p@I`@?d@F`@Vv@FBb@CFFBNC`@R|BBp@Z~CBNLVHlA?p@L~@ZjD`@xEFn@JXBNDp@?r@NdBDdA@hCK|BQdBKZ?^K\\In@@jBUZGP?JBL`@`@^j@dExFlB~C`AlBDJANK\\E\\?pAN|@JVPVZVh@HTA`@MZ]LYL]RaAFMVGVDlB?l@HJE\\H|BC\\DX\\RLrBDj@GxA@~@Df@N`AJb@E|HTH@RRHBJANUHCjAJXHxDjB`@LZZfP~JPNBJQt@Gl@wC`e@]~D@LFFJB^CXDzEbB~S`Hx@ZTNxE~Aj@VX^J?TSHA`@RnExArJNb@EFEFQF?VHrDH`@HLITHn@FvAIb@@l@PbADlHp@bETzENfA?nAGt@MNKl@m@jAuAr@aAd@[x@cAXWdDsDtBwBvJwKpCyCb@i@|AcBnA{AZSJBNX~@v@r@h@zBpAXTNRDLB\\F|DHbC@x@CnADVVNbCzCxA|Aj@r@VT^f@RJV`@`A`AXb@p@v@PZDBr@t@NAHQ`EsL\\}AXoCDEHDNh@vAnDLf@tDvIxEdLNb@xCzGhIvRrEpHlDjE^^T@PQhHiU|@kCp@}Bf@uAXg@TGJ@x@d@rEhD~A`AZZLXJn@@`@A`@Il@UpCc@|DKh@a@lASfAA`@c@jEO~B@nA^lMDZFF~Gf@xGr@pNpAlDd@fATZLDH@LALIXe@zG_@|DAl@[tDObDe@bII`@QXoC`C{@`Aq@lAWr@Oh@Kj@[xCaDx[MzBGlEEbAk@lGSx@e@v@OPcAn@s@~@s@pBMp@a@fHQfBkB~FmBrGUf@Ol@aBdF_B`Fe@`BcCzHORIBSG_@]GAGNk@jDuBbKGHG@m@a@eMwGCKAQZgADEFAXVZPF??HAAFH?GAH?WEFCMB?ALD@EE");

// [lat, long][]
// [y, x]
// Wroclaw sample: 51.1079° N, 17.0385° E

const merc = new SphericalMercator({
  size: 256,
  antimeridian: true,
});

type ParsedPoints = [number, number, number];

// const biggestLatitude = Math.max(...parsed.map((el) => el[0]));
// const biggestLongitude = Math.max(...parsed.map((el) => el[1]));
// const latitudeRange = biggestLatitude - smallestLatitude;
// const longitudeRange = biggestLongitude - smallestLongitude;

// const deg2rad = (degrees: number) => degrees * (Math.PI / 180);

function getCenterPoint(mesh: Mesh) {
  const geometry = mesh.geometry;
  geometry.computeBoundingBox();
  const center = new Vector3();
  geometry.boundingBox!.getCenter(center);
  mesh.localToWorld(center);
  return center;
}

function reduceLocations(locations: ParsedPoints[], minDistance: number) {
  let reducedLocations = [];
  let lastLocation = locations[0];
  let lastAngle = 0;

  for (let i = 1; i < locations.length; i++) {
    let location = locations[i];
    let angle = calculateAngle(lastLocation, location);
    if (
      calculateDistance(lastLocation, location) >= minDistance ||
      angle - lastAngle > 35
    ) {
      reducedLocations.push(location);
      lastLocation = location;
      lastAngle = angle;
    }
  }

  return reducedLocations;
}

function calculateAngle(location1: ParsedPoints, location2: ParsedPoints) {
  let xDiff = location2[0] - location1[0];
  let yDiff = location2[1] - location1[1];
  return (Math.atan2(yDiff, xDiff) * 180) / Math.PI;
}

function calculateDistance(location1: ParsedPoints, location2: ParsedPoints) {
  let xDiff = location2[0] - location1[0];
  let yDiff = location2[1] - location1[1];
  return Math.sqrt(xDiff * xDiff + yDiff * yDiff);
}

type Props = {
  data: FinalData[];
  rendererRef: any;
  locInteractions?: boolean;
  tiles: FinalData[][];
  generatingVideo?: boolean;
  autoDownload?: boolean;
  onVideoFinish?: () => void;
};

const Vizualization = ({
  data,
  rendererRef,
  locInteractions,
  tiles,
  generatingVideo = false,
  autoDownload = true,
  onVideoFinish,
}: Props) => {
  const sphere = React.useRef<Mesh>(null!);
  const [pathRef, setPathRef] = React.useState<Mesh>(null!);
  const cameraRef = React.useRef<RawPerspectiveCamera>(null!);
  const gl = useThree((state) => state.gl);
  const encoderRef = React.useRef<Encoder | null>(null);

  React.useEffect(() => {
    rendererRef.current = gl;
  }, [gl]);

  const parsedTiles = useMemo(
    () =>
      tiles.map((row) => {
        return row.map((point) => {
          const data = merc.px([point.location.lat, point.location.lng], 13);
          const parsedData = [data[0], data[1], point.elevation];
          return parsedData as ParsedPoints;
        });
      }),
    [tiles]
  );

  React.useEffect(() => {
    if (typeof VideoEncoder === "undefined") {
      console.log(">> NO ENCODER");
      return;
    }
    console.log(">> ENCODER PRESENT, CREATE ENCODER");
    encoderRef.current = new Encoder({
      canvas: rendererRef.current!.domElement,
      duration: fullTime,
      framerate: 60,
      videoBitrate: 15_000_000,
      autoDownload,
    });

    encoderRef.current.prepare();
  }, [generatingVideo]);

  const parsed = useMemo(() => {
    const maped = data.map((point) => {
      const data = merc.px([point.location.lat, point.location.lng], 13);
      const parsedData = [data[0], data[1], point.elevation];
      return parsedData as ParsedPoints;
    });
    return reduceLocations(maped, 15);
  }, [data]);

  const smallestLatitude = useMemo(
    () => Math.min(...parsed.map((el) => el[0])),
    [parsed]
  );
  const smallestLongitude = useMemo(
    () => Math.min(...parsed.map((el) => el[1])),
    [parsed]
  );
  const smallestAltitude = useMemo(
    () => Math.min(...parsed.map((el) => el[2])),
    [parsed]
  );

  const mappedToPoints = useMemo(
    () =>
      parsed.map(
        ([lat, long, alt]) =>
          new Vector3(
            Math.floor(long - smallestLongitude),
            Math.floor(alt - smallestAltitude) + 2,
            Math.floor(lat - smallestLatitude)
          )
      ),
    [parsed, smallestLatitude, smallestAltitude, smallestLongitude]
  );

  const tilesMappedToPoints = useMemo(
    () =>
      parsedTiles.map((row) =>
        row.map(
          ([lat, long, alt]) =>
            new Vector3(
              Math.floor(long - smallestLongitude),
              Math.floor(alt - smallestAltitude) + 2,
              Math.floor(lat - smallestLatitude)
            )
        )
      ),
    [parsedTiles, smallestLatitude, smallestAltitude, smallestLongitude]
  );
  const tilesMappedToPoints2 = useMemo(() => {
    return tilesMappedToPoints[0].map((_, index) =>
      tilesMappedToPoints.map((row) => row[index])
    );
  }, [tilesMappedToPoints]);

  const distance = useMemo(
    () =>
      mappedToPoints.reduce(
        (acc: { previous: Vector3 | null; distance: number }, current) => {
          if (!acc.previous) {
            return {
              previous: current,
              distance: 0,
            };
          }
          return {
            previous: current,
            distance: acc.distance + acc.previous.distanceTo(current),
          };
        },
        { previous: null, distance: 0 }
      ),
    [mappedToPoints]
  ).distance;

  const fullTime = 20;

  useFrame(async ({ clock }) => {
    if (sphere.current) {
      const elapsed = clock.getElapsedTime();
      const currentLoopTime = elapsed % fullTime;
      const percentage = currentLoopTime / fullTime;
      sphere.current.position.set(
        ...pathCurve.getPointAt(percentage).toArray()
      );

      // video generation
      if (generatingVideo && encoderRef.current) {
        const finished = await encoderRef.current!.addFrame();
        if (finished && onVideoFinish) {
          onVideoFinish();
          if (!autoDownload) {
            const stringifiedBlob =
              await encoderRef.current.getStringifiedBlob();
            (window as any).onRenderFinish(stringifiedBlob);
          }
        }
      }
    }
  });

  useEffect(() => {
    if (cameraRef) {
      cameraRef.current.position.z = -2000;
    }
  }, [data]);

  const pathCurve = useMemo(() => {
    return new CatmullRomCurve3(mappedToPoints, false, "centripetal");
  }, [mappedToPoints]);
  return (
    <>
      {data.length && (
        <>
          <PerspectiveCamera
            position={[857, 400, 234]}
            makeDefault
            fov={20}
            near={500}
            far={21000}
            ref={cameraRef}
          />
          <color attach="background" args={["#333333"]} />
          <OrbitControls
            autoRotate
            autoRotateSpeed={3}
            enableDamping={false}
            minPolarAngle={0.9}
            maxPolarAngle={0.9}
            enableZoom={!locInteractions}
            minAzimuthAngle={locInteractions ? 1 : undefined}
            maxAzimuthAngle={locInteractions ? 1 : undefined}
            target={pathRef ? getCenterPoint(pathRef) : undefined}
            minDistance={1000}
            maxDistance={20000}
          />
          <mesh ref={sphere}>
            <sphereGeometry args={[12, 20, 20]} />
            <meshToonMaterial attach="material" color={"#bababa"} />
          </mesh>
          <directionalLight position={[0, 2000, 0]} />

          <mesh>
            <Tube
              ref={(newRef) => setPathRef(newRef as Mesh)}
              args={[pathCurve, 700, 2, 4]}
            >
              <meshToonMaterial attach="material" color={"#bababa"} />
            </Tube>
          </mesh>
          <mesh position={mappedToPoints[0].toArray()}>
            <sphereGeometry args={[7, 20, 20]} />
            <meshToonMaterial attach="material" color={"#bababa"} />
          </mesh>
          <mesh position={mappedToPoints[mappedToPoints.length - 1].toArray()}>
            <sphereGeometry args={[7, 20, 20]} />
            <meshToonMaterial attach="material" color={"#bababa"} />
          </mesh>

          {tilesMappedToPoints.map((points, index) => (
            <>
              <Line
                points={points}
                color="#515151"
                lineWidth={1}
                key={index}
                getObjectsByProperty={undefined}
                forceSinglePass={undefined}
                getVertexPosition={undefined}
              />
            </>
          ))}
          {tilesMappedToPoints2.map((points, index) => (
            <>
              <Line points={points} color="#515151" lineWidth={1} key={index} />
            </>
          ))}
        </>
      )}
    </>
  );
};

export default Vizualization;
