/** @jsxImportSource @emotion/react */
import Visualization from "./Visualization";
import Sidebar from "./components/Sidebar";
import Canvas from "./components/Canvas";
import Activities from "./Activities";
import { useEffect, useRef, useState, useCallback } from "react";
import { Activity } from "./types";
import StartScreen from "./StartScreen";
import Loader from "./Loader";
import { css } from "@emotion/react";
import { WebGLRenderer } from "three";

const downloadButtonStyle = (generatingVideo: boolean) => css`
  color: #fff;
  background-color: #7158d3;
  border-radius: 12px;
  text-decoration: none;
  padding: 10px 15px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 10;
  appearance: none;
  border: 0;
  font-size: inherit;
  font-family: inherit;
  opacity: ${generatingVideo ? "0.8" : "1"};
`;

const containerStyle = css`
  display: flex;
  align-items: stretch;
  height: 100%;
`;

const canvasContainerStyle = css`
  flex-grow: 1;
  background-color: #333;
  flex-shrink: 1;
  min-width: 0px;
`;

const downloadButton = css`
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 10;
`;

export type FinalData = {
  location: {
    lat: number;
    lng: number;
  };
  elevation: number;
};

export const parsePolyline = async (polylineData: string) => {
  const URL = `${process.env.REACT_APP_SERVER_ADDRESS}/altitudes`;
  const res = await fetch(URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      polyline: polylineData,
    }),
  });
  const data = await res.json();
  return { data: data.data, tiles: data.tiles };
};

const urlParams = new URLSearchParams(window.location.search);
const urlToken = urlParams.get("token");
const token = localStorage.getItem("token");

const navigateToHomePage = () => {
  window.location.href = process.env.REACT_APP_WEBAPP_ADDRESS!;
};

if (urlToken) {
  localStorage.setItem("token", urlToken);
  navigateToHomePage();
}

// function exportVid(blob: Blob) {
//   const videoElement = document.createElement("video");
//   videoElement.src = URL.createObjectURL(blob);
//   videoElement.controls = true;
//   document.body.appendChild(videoElement);
//   const linkElement = document.createElement("a");
//   linkElement.download = "paintmove-export.webm";
//   linkElement.href = videoElement.src;
//   linkElement.textContent = "download the video";
//   document.body.appendChild(linkElement);
//   linkElement.click();
//   document.body.removeChild(linkElement);
//   document.body.removeChild(videoElement);
// }

function App() {
  const [data, setData] = useState<FinalData[] | null>(null);
  const [tiles, setTiles] = useState<FinalData[][] | null>(null);
  const [activities, setActivities] = useState<Activity[] | null>(null);
  const [generatingVideo, setGeneratingVideo] = useState<boolean>(false);
  const [activeActivityId, setActiveActivityId] = useState<number | null>(null);
  const [currentActivityPage, setCurrentActivityPage] = useState<number>(1);
  const [loadingActivities, setLoadingActivities] = useState<boolean>(false);
  const [hasMoreActivities, setHasMoreActivities] = useState<boolean>(true);
  const rendererRef = useRef<WebGLRenderer | null>(null);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    if (!activeActivityId) {
      return;
    }
    const activityData = activities?.find(
      (activity) => activity.id === activeActivityId
    )?.map.summary_polyline;
    if (!activityData) {
      return;
    }
    parsePolyline(activityData).then((data) => {
      setData(data.data);
      setTiles(data.tiles);
    });
  }, [activeActivityId, activities]);
  useEffect(() => {
    if (!token || loadingActivities) {
      return;
    }
    setLoadingActivities(true);
    fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/activities`, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ page: currentActivityPage }),
    })
      .then((res) => {
        if (res.status !== 200) {
          localStorage.removeItem("token");
          navigateToHomePage();
        }
        return res.json();
      })
      .then((data) => {
        setActivities([...(activities || []), ...data.activities]);
        if (!activeActivityId) {
          setActiveActivityId(data.activities[0].id);
        }
        if (data.activities.length < 30) {
          setHasMoreActivities(false);
        }
      })
      .catch(() => {
        localStorage.removeItem("token");
        navigateToHomePage();
      })
      .finally(() => {
        setLoadingActivities(false);
      });
  }, [currentActivityPage]);

  const handleActivityClick = (id: number) => {
    setShowMenu(false);
    setData(null);
    setActiveActivityId(id);
  };

  const handleDownloadClick = () => {
    setGeneratingVideo(true);
  };
  return (
    <div css={containerStyle}>
      {activities && (
        <Sidebar
          showMenu={showMenu}
          onMenuButtonClick={() => setShowMenu(true)}
        >
          <Activities
            onMoreItemsRequest={() => {
              if (!loadingActivities) {
                setCurrentActivityPage(currentActivityPage + 1);
              }
            }}
            hasMoreItems={hasMoreActivities}
            loading={loadingActivities}
            activities={activities}
            activeActivityId={activeActivityId}
            onActivityClick={handleActivityClick}
          />
        </Sidebar>
      )}
      {!token && <StartScreen />}
      {token && (
        <div css={canvasContainerStyle}>
          {!data && <Loader />}
          {data && typeof VideoEncoder !== "undefined" && (
            <button
              onClick={handleDownloadClick}
              css={downloadButtonStyle(generatingVideo)}
              disabled={generatingVideo}
            >
              {generatingVideo ? "Generating..." : "Download as movie"}
            </button>
          )}

          <Canvas>
            {data && tiles && (
              <Visualization
                tiles={tiles}
                data={data}
                rendererRef={rendererRef}
                locInteractions={false}
                generatingVideo={generatingVideo}
                onVideoFinish={() => setGeneratingVideo(false)}
              />
            )}
          </Canvas>
        </div>
      )}
    </div>
  );
}

export default App;
