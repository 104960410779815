/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import React from "react";
import { Activity } from "./types";
import useInfiniteScroll from "react-infinite-scroll-hook";

type Props = {
  activities: Activity[];
  onActivityClick: (id: number) => void;
  activeActivityId: number | null;
  onMoreItemsRequest: () => void;
  loading: boolean;
  hasMoreItems: boolean;
};

const buttonStyle = (isCurrent: boolean) => css`
  padding: 15px 10px;
  display: block;
  appearance: none;
  text-align: left;
  border: 0;
  background: ${isCurrent ? "#7158D3" : "transparent"};
  width: 100%;
  color: ${isCurrent ? "#fff" : "inherit"};
  border-radius: 8px;
  font-size: 0.9em;
`;

const activityTopRowStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const nameStyle = (isCurrent: boolean) => css`
  color: ${isCurrent ? "#fff" : "inherit"};
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const loadingStyle = css`
  text-align: center;
`;

const dateStyle = css`
  font-size: 0.85em;
  white-space: nowrap;
`;

const activityDetailsStyle = css`
  font-size: 0.85em;
`;

const Activities = ({
  activities,
  onActivityClick,
  activeActivityId,
  onMoreItemsRequest,
  loading,
  hasMoreItems,
}: Props) => {
  const [sentryRef] = useInfiniteScroll({
    loading: false,
    hasNextPage: true,
    onLoadMore: onMoreItemsRequest,
    rootMargin: "0px 0px 0px 0px",
  });

  const grouppedActivities = activities.reduce(
    (acc: { [key: string]: Activity[] }, current) => {
      const date = new Date(current.start_date_local);
      const dateString = `${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()}`;
      return {
        ...acc,
        [dateString]: [...(acc[dateString] || []), current],
      };
    },
    {}
  );
  return (
    <>
      {Object.keys(grouppedActivities).map((date) => (
        <React.Fragment key={date}>
          {grouppedActivities[date].map((activity) => (
            <div key={activity.id}>
              <button
                onClick={() => {
                  onActivityClick(activity.id);
                }}
                css={buttonStyle(activity.id === activeActivityId)}
              >
                <div css={activityTopRowStyle}>
                  <div css={nameStyle(activity.id === activeActivityId)}>
                    {activity.name}
                  </div>
                  <div css={dateStyle}>{date}</div>
                </div>
                <div css={activityDetailsStyle}>
                  <div>{activity.nearest_city}</div>

                  <span>{(activity.distance / 1000).toFixed(2)} km</span>
                  {" • "}
                  <span>
                    {Math.floor(activity.moving_time / 60)}m{" "}
                    {activity.moving_time -
                      Math.floor(activity.moving_time / 60) * 60}
                    s
                  </span>
                </div>
              </button>
            </div>
          ))}
        </React.Fragment>
      ))}
      {hasMoreItems && (
        <div ref={sentryRef} css={loadingStyle}>
          {loading && <div>Loading...</div>}
        </div>
      )}
    </>
  );
};

export default Activities;
